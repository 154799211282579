import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  VStack,
} from "@chakra-ui/react";
import {
  FieldValues,
  get,
  useFormContext,
  UseFormSetError,
  useWatch,
  Validate,
} from "react-hook-form";
import { ONE_HUNDRED_YEARS_AGO, THIS_YEAR } from "./DateInput";
import { ChangeEvent, forwardRef, useEffect, useState } from "react";
import { t } from "@lingui/macro";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactInputMask from "react-input-mask";
import { CalendarIcon, CloseIcon, Icon } from "@chakra-ui/icons";
import { setValues } from "framer-motion/types/render/utils/setters";

const now = DateTime.now();
export const TEN_YEARS_AGO_DATE = now.minus({ years: 10 });
export const MIN_DATE_FULL = now.minus({ years: 100 }).startOf("day");
export const MAX_DATE_FULL = now.startOf("day");

export function hasFullDateStringGreaterThanMaxDate(
  fullDateString: string,
  maxDate: DateTime,
) {
  let dateTime;

  if (fullDateString) {
    dateTime = DateTime.fromISO(fullDateString).endOf("day");
  }

  return dateTime && maxDate && dateTime > maxDate;
}

export function isStartDateBeforeEndDate(
  startDateString: string,
  endDateString: string,
) {
  let startDateTime;
  let endDateTime;

  if (startDateString) {
    startDateTime = DateTime.fromISO(startDateString);
  }

  if (endDateString) {
    endDateTime = DateTime.fromISO(endDateString);
  }

  return startDateTime && endDateTime && startDateTime < endDateTime;
}

export function validateFormDate(formDate: string) {
  if (!formDate) {
    return "This is required";
  }

  const formDateTime = formDate && DateTime.fromISO(formDate);
  if (!formDateTime || !formDateTime.isValid) {
    return "Invalid date";
  }

  return true;
}

export function validateDateOfBirth(dateOfBirthString: string) {
  const validOrErrorMessage = validateFormDate(dateOfBirthString);

  if (DateTime.fromISO(dateOfBirthString) > TEN_YEARS_AGO_DATE) {
    return "Please enter a year no later than " + TEN_YEARS_AGO_DATE.year;
  }
  return validOrErrorMessage;
}

export interface DatePickerInputProps {
  parentId?: string;
  fieldIds?: { month?: string; day?: string; year?: string; date?: string };
  label: string;
  //default value in YYYY-MM-DD format
  defaultMonth?: string;
  defaultDay?: string;
  defaultYear?: string;
  placeholderMonth?: string;
  placeholderDay?: string;
  placeholderYear?: string;
  defaultPlaceholders?: boolean;
  required?: boolean;
  helperText?: string;
  requiredMinDate?: boolean;
  minYear?: number;
  maxYear?: number;
  minDate?: DateTime;
  maxDate?: DateTime;
  validateMonth?: Validate<any> | Record<string, Validate<any>>;
  validateDay?: Validate<any> | Record<string, Validate<any>>;
  validateYear?: Validate<any> | Record<string, Validate<any>>;
  validateDate?: Validate<any> | Record<string, Validate<any>>;
  hidden?: boolean;
}
export function DatePickerInput({
  parentId,
  fieldIds = { month: "month", day: "day", year: "year" },
  label,
  //TODO: remove when no longer needed
  defaultMonth,
  defaultDay,
  defaultYear,
  placeholderMonth,
  placeholderDay,
  placeholderYear,
  defaultPlaceholders,
  required = true,
  helperText,
  requiredMinDate = false,
  minYear = ONE_HUNDRED_YEARS_AGO,
  maxYear = THIS_YEAR,
  minDate = MIN_DATE_FULL,
  maxDate = MAX_DATE_FULL,
  validateMonth,
  validateDay,
  validateYear,
  validateDate,
  hidden = false,
}: DatePickerInputProps) {
  const {
    register,
    formState,
    control,
    setFocus,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useFormContext();
  const inputIsAccessible = !hidden;
  const validateRequired = inputIsAccessible && required;
  const validateRequiredByFunction =
    (validateRequired && validateDate !== undefined) || !validateRequired;
  const validateRequiredMinDate = inputIsAccessible && requiredMinDate;
  const validateMinDate = inputIsAccessible && minDate;
  const validateMaxDate = inputIsAccessible && maxDate;
  const parentPath = parentId ? parentId + "." : "";
  const pathIds = {
    month: parentPath + fieldIds.month,
    day: parentPath + fieldIds.day,
    year: parentPath + fieldIds.year,
  };

  let yearValueErrMsg: string;

  if (minYear && maxYear) {
    yearValueErrMsg = t`Please enter a year in the range of ${minYear}-${maxYear}`;
  } else if (minYear) {
    yearValueErrMsg = t`Please enter a year no earlier than ${minYear}`;
  } else if (maxYear) {
    yearValueErrMsg = t`Please enter a year no later than ${maxYear}`;
  } else {
    // Will never be seen, but set here anyways to satisfy lint.
    yearValueErrMsg = "";
  }

  const fieldParts = fieldIds?.month?.split(".");
  let addressPath = "";
  if (fieldParts && fieldParts.length > 1) {
    addressPath = addressPath + fieldIds?.month?.split(".")[0];
  }
  if (fieldParts && fieldParts.length === 3) {
    addressPath = addressPath + "." + fieldParts[1];
  }
  const dateLabelParts = fieldIds?.date?.split(".");
  let dateLabel = "";
  if (dateLabelParts && dateLabelParts.length === 1) {
    dateLabel = dateLabelParts[0];
  }
  if (dateLabelParts && dateLabelParts.length === 2) {
    dateLabel = "." + dateLabelParts[1];
  }
  if (dateLabelParts && dateLabelParts.length === 3) {
    dateLabel = "." + dateLabelParts[2];
  }
  const dateId = parentPath + addressPath + dateLabel;
  const savedDate = getValues(dateId);
  // fromIso() returns a DateTime object regardless of what was passed in
  // use isValid to avoid calling methods on invalid DateTime objects
  const savedDateTime = DateTime.fromISO(savedDate);
  const [dateValue, setDateValue] = useState<Date | null>(
    savedDateTime.isValid ? savedDateTime.toJSDate() : null,
  );

  useEffect(() => {
    setValue(dateId, savedDateTime.isValid ? savedDateTime.toISODate() : null);
  }, [dateValue]);
  const [formattedDatePreview, setFormattedDatePreview] = useState<
    string | null
  >(
    savedDateTime.isValid
      ? savedDateTime.toLocaleString(DateTime.DATE_FULL)
      : null,
  );
  const hasInvalidMinDate =
    validateRequiredMinDate && get(formState.errors, `${parentPath}.minDate`);
  const [dateWatchValue] = useWatch({
    control,
    name: [dateId],
  });

  const handleEnterKeyPress = (e: any) => {
    const userAgent = navigator.userAgent;
    if (
      e.key === "Enter" &&
      userAgent.indexOf("Chrome") === -1 &&
      userAgent.indexOf("Safari") !== -1
    ) {
      const value = e.target.value;
      const date = DateTime.fromFormat(value, "MM / dd / yyyy");
      if (date.isValid) {
        handleDateChange(date.toJSDate());
      }
    }
  };

  const handleDateChange = (date: Date) => {
    // Example: Showing form error messages for minimum date
    // if (DateTime.fromJSDate(date) < minDate) {
    //   setError(dateId, {
    //     type: "min",
    //     message: t`Please enter a date no earlier than ${minDate.toFormat("MM/dd/yyyy")}`,
    //   });
    // } else {
    //   clearErrors(dateId)
    // }
    setDateValue(date);
    setFormattedDatePreview(
      DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_FULL),
    );
    setValue(dateId, DateTime.fromJSDate(date).toFormat("yyyy-MM-dd"));
    clearErrors(dateId);
  };
  return (
    <Box hidden={hidden} py="0.5rem" width={"fit-content"}>
      <FormLabel
        className={validateRequired ? "requiredField" : "optionalField"}>
        {label}
      </FormLabel>
      <FormControl
        isInvalid={hasInvalidMinDate || get(formState.errors, dateId)}>
        <VStack alignItems="flexStart">
          <InputGroup size="md">
            <DatePicker
              selected={dateValue}
              onChange={handleDateChange}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleEnterKeyPress(event);
                }
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              minDate={minDate.toJSDate()}
              maxDate={maxDate.toJSDate()}
              placeholderText="MM/DD/YYYY"
              customInput={
                <Input
                  id={dateId}
                  as={ReactInputMask}
                  borderColor={"gray.600"}
                  mask="99 / 99 / 9999"
                  {...register(dateId, {
                    required: {
                      value: !validateRequiredByFunction,
                      message: t`This is required`,
                    },
                    validate: (inputIsAccessible && validateDate) || undefined,
                  })}
                />
              }
            />
            <InputRightElement
              zIndex={0}
              width="4.5rem"
              pointerEvents={dateValue ? "all" : "none"}>
              {dateValue ? (
                <CloseIcon
                  onClick={() => {
                    setDateValue(null);
                    setFormattedDatePreview("");
                    setValue(dateId, null);
                  }}
                  cursor="pointer"
                />
              ) : (
                <CalendarIcon />
              )}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>
            {get(formState.errors, `${dateId}.message`) ||
              (hasInvalidMinDate && "End Date must be after Start Date")}
          </FormErrorMessage>
          {formattedDatePreview && (
            <FormHelperText>{formattedDatePreview}</FormHelperText>
          )}
          {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </VStack>
      </FormControl>
    </Box>
  );
}
